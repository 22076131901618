<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col class="d-flex justify-content-between flex-column md-6">
        <b-row class="align-items-center mb-2">
          <b-col cols="12" md="2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              class=""
              @click="$router.go(-1)"
            >
              <feather-icon icon="ArrowLeftIcon" class="mr-25" />
              <span>List</span>
            </b-button>
          </b-col>
          <b-col cols="12" md="6">
            <h4>Karma Category Details:</h4>
          </b-col>
          <b-col cols="12" md="6" />
        </b-row>
        <b-row>
          <b-col md="6">
            <b-row>
              <b-col md="6" class="pb-50 font-weight-bold"> Name </b-col>
              <b-col md="6" class="pb-50">
                {{ userData.name }}
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6" v-if="userData.parent">
            <b-row>
              <b-col md="6" class="pb-50 font-weight-bold"> Parent </b-col>
              <b-col md="6" class="pb-50">
                <!-- <b-link
                  :to="{
                    name: 'view-karma-category',
                    params: { id: userData.parent.hashid },
                  }"
                  class="font-weight-bold d-block text-nowrap"
                > -->
                {{ userData.parent.name }}
                <!-- </b-link> -->
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col md="6" class="pb-50 font-weight-bold"> Description </b-col>
              <b-col md="6" class="pb-50">
                {{ userData.description }}
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col md="6" class="pb-50 font-weight-bold">
                Karma Category Status
              </b-col>
              <b-col md="6" class="pb-50">
                {{ userData.status == "1" ? "Active" : "In Active" }}
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6" v-if="!userData.parent">
            <b-row>
              <b-col md="6" class="pb-50 font-weight-bold">
                Max Karma Point (0 = Unlimited)
              </b-col>
              <b-col md="6" class="pb-50">
                {{ userData.max_karma_point }}
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col md="6" class="pb-50 font-weight-bold">
                Karma Category Owners
              </b-col>
              <b-col md="6" class="pb-50" v-if="userData.parent">
                <b-media
                  v-if="userData.parent.users"
                  v-for="user in userData.parent.users"
                  :key="user.id"
                >
                  <template #aside>
                    <b-avatar :src="user.avatar" />
                  </template>
                  <h6 class="mb-0">{{ user.name }}</h6>
                  <p class="">{{ user.designation }}</p>
                </b-media>
              </b-col>
              <b-col md="6" class="pb-50" v-else>
                <b-media
                  v-if="userData.users"
                  v-for="user in userData.users"
                  :key="user.id"
                >
                  <template #aside>
                    <b-avatar :src="user.avatar" />
                  </template>
                  <h6 class="mb-0">{{ user.name }}</h6>
                  <p class="">{{ user.designation }}</p>
                </b-media>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-for="(data, index) in customFields" v-bind:key="index" md="6">
        <b-row>
          <b-col md="6" class="pb-50 font-weight-bold">
            {{ data.label }}
          </b-col>
          <b-col md="6" class="pb-50">
            {{ userData.get_customfields_data["field_" + data.id] }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BButton, VBTooltip } from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    customFields: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      userDataLocal: this.$cookies.get("userData"),
    };
  },
  methods: {
    openWindow(link) {
      window.open(link);
    },
  },
};
</script>

<style>
</style>
